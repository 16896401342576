@import url('https://api.fonts.coollabs.io/css2?family=Roboto+Mono:wght@300&display=swap');

* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  background: #f0f0f0;
}


.terminal-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: lightgrey;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7.5px;
  border: #000000;
  border-style: solid;
  border-width: 10px;
  font-family: 'Roboto Mono', monospace;
  font-size: 12px;
}

.terminal-bar .label {
  margin-left: 10px;
}

.terminal-bar .contact {
  margin-right: 10px;
  cursor: pointer;
  color: #000;
  text-decoration: underline;
}


.canvas-container {
  height: calc(100vh);
  width: 100%;
}


.menu-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
}

.contact-page {
  position: fixed;
  bottom: 50px; /* adjust this to match TerminalBar height */
  width: 100%;
  height: calc(100vh - 50px); /* adjust this to match TerminalBar height */
  background: #000;
  color: #fff;
  font-family: 'Roboto Mono', monospace;
  font-size: 12px;
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-content {
  text-align: center;
  max-width: 800px;
  margin: auto;
  padding: 20px;
}


.contact-content a {
  color: white;
  text-decoration: none; /* optional, to remove underline */
}
